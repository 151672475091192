
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import AppHeader from '~/components/common/AppHeader/AppHeader.vue'
import SystemNotificationsProvider from '@/components/SystemNotifications/SystemNotificationsProvider.vue'

@Component({
  components: {
    SystemNotificationsProvider,
    AppHeader
  },
  middleware: ['auth']
})
export default class extends Vue {
  includedScript: boolean = false

  get user () {
    return this.$store.getters['user/user']
  }

  @Watch('user')
  public setAmoMeta (val: any) {
    if (val && !this.includedScript) {
      const script =
        `amo_social_button.setMeta(
              {
                lead: {
                  custom_fields: [
                    {
                      id: '1242339',
                      values: [
                        { value: ${val.id} }
                      ]
                    }
                  ]
                }
              }
            )`
      document.head.prepend(Object.assign(document.createElement('script'), { innerHTML: script }))
      this.includedScript = true
    }
  }
}
